import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Investment } from '../../../../app/models/Investment/Investment';
import { useStore } from '../../../../app/stores/store';
import EntityContext from '../../../../app/context/entityContext';
import { useDebounce } from '../../../../shared/useDebounce';
import { booleanOptions, formatCurrency, getFloatValue, getFullSizeWidth } from '../../../../shared/utils';
import { CalculateMonthlyFinancial } from '../../../../app/models/Investment/CalculateMonthlyFinancial';
import * as yup from 'yup';
import { MonthlyInvestmentFinancialFormValues } from '../../../../app/models/Investment/MonthlyInvestmentFinancial';
import { toast } from 'react-toastify';
import { Button, Divider, Form, Grid, GridRow, Header, Icon, Message, Segment, Table } from 'semantic-ui-react';
import { Formik } from 'formik';
import CurrencyInput from '../../../../app/common/form/CurrencyInput';
import DateInput from '../../../../app/common/form/DateInput';
import SelectInput from '../../../../app/common/form/SelectInput';
import TextInput from '../../../../app/common/form/TextInput';
import { InputMasks } from '../../../../shared/InputMasks';
import { addMonths } from "date-fns"
import { ShareClassification } from '../../../../app/models/Investment/ShareClassification';
import EmptyGridMessage from '../../../../shared/EmptyGridMessage';
import { useMediaQuery } from 'react-responsive';

interface Props {
    investment: Investment;
    editFinancialId: number;
    allowEdit?: boolean;
    addNew?: boolean;
    onCancel?: () => void;
}

function FinancialsForm({ investment, editFinancialId, addNew, onCancel, allowEdit = false }: Props) {

    const emptyOption = { key: '' };
    const { commonStore, investmentStore } = useStore();
    const [interestTypeOptions, setInterestTypeOptions] = useState([emptyOption]);

    const { entity } = useContext(EntityContext);
    const [loading, setLoading] = useState(false);
    const [loadingCalculation, setLoadingCalculation] = useState(false);

    const [newFinancial, setNewFinancial] = useState(false);
    const [editMode] = useState(allowEdit);
    const [monthlyFinancial, setMonthlyFinancial] = useState(investment.filteredFinancialInfo);
    const [calculateValuation, setCalculateValuation] = useState<number>(0);
    const debounceCalculateValuation = useDebounce<number>(calculateValuation, 500);
    const formikRef = useRef<any>();
    const [valuesChanged, setValuesChanged] = useState(false);
    const [shares, setShares] = useState<ShareClassification[]>([]);
    const showFullSize = useMediaQuery({ query: `(${getFullSizeWidth()})` })

    const GetSharesValues = useCallback((values: any, formikContext: any) => {
        setLoadingCalculation(true);
        const calcInput: CalculateMonthlyFinancial = {
            investmentId: investment.id,
            monthlyInvestmentFinancialId: values.id,
            valuation: values.valuation,
            totalLoan: values.totalLoan,
            noi: values.noi,
        };
        investmentStore.calculateMonthlyFinancial(calcInput)
            .then(r => {
                if (formikContext !== null) {
                    formikContext.setFieldValue("capRate", r.capRate);
                }
                setShares(r.sharesValue);
            })
            .finally(() => setLoadingCalculation(false));
    }, [investment.id, investmentStore])

    const getHistoricFinancialInfo = useCallback((id: number) => {
        setLoading(true);
        investmentStore.getMonthlyInvestmentFinancial(entity.id, investment.id, id)
            .then((r) => {
                let financial = new Investment(r).filteredFinancialInfo;
                setMonthlyFinancial(financial);
                GetSharesValues(financial, null);
            })
            .finally(() => setLoading(false));
    }, [entity.id, investment.id, investmentStore, GetSharesValues])

    const newMonthlyFinancial = useCallback(() => {
        setCalculateValuation(0);
        const newFinancial = { ...investment.currentFinancialInfo };
        newFinancial.id = 0;
        if (newFinancial.valuation === 0) {
            newFinancial.valuation = Number(investment.valueAtAcquisition);
        }
        Object.entries(newFinancial).map(([key, value]) => (
            formikRef.current.setFieldValue(key, value)
        ))
        formikRef.current.setFieldValue("asofDate", "");
        GetSharesValues(newFinancial, null);
        setNewFinancial(true);
    }, [investment, GetSharesValues])

    useEffect(() => {
        if (addNew) {
            newMonthlyFinancial();
        }
        else if (editFinancialId > 0) {
            getHistoricFinancialInfo(editFinancialId);
        }
    }, [addNew, editFinancialId, getHistoricFinancialInfo, newMonthlyFinancial])

    useEffect(() => {
        commonStore.getInvestmentEnums()
            .then(r => {
                setInterestTypeOptions(r.interestType);
            })
    }, [commonStore, setInterestTypeOptions]);

    useEffect(() => {
        const formikContext = formikRef.current;
        const values = formikContext.values;

        if (getFloatValue(values.valuation) === 0 || getFloatValue(values.noi) === 0) {
            formikContext.setFieldValue("capRate", 0);
            formikContext.setFieldValue("shareValue", 0);
        }

        if (debounceCalculateValuation > 1 && valuesChanged && values.valuation !== '' && values.totalLoan !== '' && values.noi !== '') {
            setLoadingCalculation(true);
            GetSharesValues(values, formikContext);
        }
    }, [debounceCalculateValuation, investment.id, investmentStore, valuesChanged, GetSharesValues]);

    const runValuation = (originalValue: number, newValue: number) => {
        if (originalValue !== newValue) {
            setValuesChanged(true);
        }
        setCalculateValuation(calculateValuation + 1);
    };

    const validationSchema = yup.object({
        asofDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("As of Date is required"),
        valuation: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Valuation is required')
            .positive('Valuation must be greater than 0'),
        occupancy: yup
            .number()
            .required('Occupancy is required')
            .positive('Percentage must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        netIncome: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Net Income is required'),
        noi: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('NOI is required')
            .positive('NOI must be greater than 0'),
        lender: yup
            .string()
            .required('Lender is required'),
        term: yup
            .number()
            .required('Term is required')
            .positive('Term must be greater than 0'),
        ownedSince: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Owned Since Date is required"),
        interestRate: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Interest Rate is required')
            .positive('Interest Rate must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        interestType: yup
            .number()
            .min(1, 'Interest Type is required'),
        loanToValue: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Loan to Value is required')
            .positive('Loan to Value must be greater than 0')
            .max(1000, 'Number must be lower than 1000'),
        interestOnly: yup
            .number()
            .min(0, 'Interest Only is required'),
        interestYears: yup
            .number()
            .when('interestOnly', {
                is: 1,
                then: yup.number()
                    .transform((_value, originalValue) => getFloatValue(originalValue))
                    .required('Years is required')
                    .min(1, 'Years must be greater than 0'),
            })
            .nullable(),
        debtCoverage: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Debt Coverage is required')
            .positive('Debt Coverage must be greater than 0'),
        annualDebtService: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Annual Debt Service is required')
            .positive('Annual Debt Service must be greater than 0'),
        totalLoan: yup
            .number()
            .transform((_value, originalValue) => getFloatValue(originalValue))
            .required('Total Loan is required'),
        loanStartDate: yup
            .date()
            .typeError("Please enter a valid date")
            .required("Loan Start Date is required"),
    });

    const reset = (resetForm: any) => {
        if (onCancel) onCancel();
    }

    const handleFormSubmit = async (values: any, setFieldError: any, setSubmitting: any) => {
        values.asofDate = new Date(values.asofDate);
        values.ownedSince = new Date(values.ownedSince);
        values.month = values.asofDate.getMonth() + 1;
        values.year = values.asofDate.getFullYear();
        values.loanStartDate = new Date(values.loanStartDate);
        const financial = new MonthlyInvestmentFinancialFormValues(values);

        if (newFinancial) {
            financial.id = 0;
        }

        investmentStore.saveMonthlyFinancial(investment.id, financial)
            .then(response => {
                if (response !== null) {
                    setMonthlyFinancial(financial);
                    if (onCancel) onCancel();
                    toast.success("Success!", { theme: "colored" });
                }
            })
            .catch(err => {
                toast.error("There was an issue saving the monthly financial info.", { theme: "colored" });
            })
            .finally(() => setSubmitting(false));
    }

    const onAnnualizedChange = (value: number, setFieldValue: any, fieldName: string) => {
        let amount = 0;
        if (value !== undefined) {
            amount = value * 12;
        }
        setFieldValue(fieldName, amount);
    };

    return (
        <Segment basic loading={loading}>
            <Formik
                enableReinitialize
                initialValues={monthlyFinancial}
                validationSchema={validationSchema}
                onSubmit={(values, { setFieldError, setSubmitting }) => {
                    handleFormSubmit(values, setFieldError, setSubmitting);
                }}
                innerRef={formikRef}
            >
                {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete='Off'>

                        {editMode &&
                            <Message info header={(newFinancial ? "Add New" : "Update") + " Financial"} />
                        }

                        <Divider horizontal section>
                            <Header as='h4'>
                                Monthly Financials
                            </Header>
                        </Divider>

                        {editMode &&
                            <Grid columns={3} >
                                <Grid.Row>
                                    <Grid.Column textAlign='left'>
                                        <Form.Group widths='equal'>
                                            <DateInput placeholder='As of Date' name='asofDate' mask={InputMasks.monthYear} dateFormat='MM/yyyy' showRequired showMonthYearPicker startDate={newFinancial ? addMonths(new Date(investment.currentFinancialInfo.asofDate), 1) : values.asofDate} />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }
                        <Form.Group widths='equal'>
                            <CurrencyInput placeholder='Total Income' name='totalIncome' maxLength={13} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={editMode ? '' : '$'} allowNegative={false} showRequired={editMode} onChange={(e) => onAnnualizedChange(e, setFieldValue, "annualizedTotalIncome")} />
                            <CurrencyInput placeholder='NOI' name='noi' maxLength={13} thousandSeparator=',' decimalScale={2} readOnly={!editMode} allowNegative={false} prefix={editMode ? '' : '$'} showRequired={editMode} onChange={(e) => { onAnnualizedChange(e, setFieldValue, "annualizedNOI"); runValuation(values.noi, e); }} />
                            <CurrencyInput placeholder='Net Income' name='netIncome' maxLength={13} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={editMode ? '' : '$'} allowNegative={false} showRequired={editMode} onChange={(e) => onAnnualizedChange(e, setFieldValue, "annualizedNetIncome")} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <CurrencyInput placeholder='Annualized Total Income' name='annualizedTotalIncome' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly prefix={'$'} className={editMode ? "readOnlyField" : ""} />
                            <CurrencyInput placeholder='Annualized NOI' name='annualizedNOI' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly prefix={'$'} className={editMode ? "readOnlyField" : ""} />
                            <CurrencyInput placeholder='Annualized Net Income' name='annualizedNetIncome' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly prefix={'$'} className={editMode ? "readOnlyField" : ""} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <CurrencyInput placeholder='Total Operating Expenses' name='totalOperatingExpenses' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={'$'} className={!editMode ? "readOnlyField" : ""} />
                            <CurrencyInput placeholder='Total Non-Operating Expenses' name='totalNonOperatingExpenses' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={'$'} className={!editMode ? "readOnlyField" : ""} />
                            <TextInput placeholder='Occ. %' name='occupancy' maxLength={6} icon={<Icon name='percent' />} readOnly={!editMode} showRequired={editMode} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <CurrencyInput placeholder='Latest Valuation' name='valuation' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly={!editMode} allowNegative={false} prefix={editMode ? '' : '$'} showRequired={editMode} onChange={(e) => runValuation(values.valuation, e)} />
                            <TextInput placeholder='Cap Rate' name='capRate' maxLength={6} icon={<Icon name='percent' />} readOnly showRequired={editMode} className={editMode ? "readOnlyField" : ""} />
                        </Form.Group>

                        {shares.length > 0 &&
                            <Grid columns={3}>
                                <GridRow>
                                    <Grid.Column width={3} />
                                    <Grid.Column width={10}>
                                        {loadingCalculation ?
                                            <Segment basic loading />
                                            :
                                            <Table singleLine compact>
                                                {showFullSize &&
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>Share</Table.HeaderCell>
                                                            <Table.HeaderCell textAlign='center'>Share Count</Table.HeaderCell>
                                                            <Table.HeaderCell textAlign='center'>Share Value</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                }
                                                <Table.Body>
                                                    {!loading && shares.map((share, i) => {
                                                        if (showFullSize) {
                                                            return <Table.Row key={i}>
                                                                <Table.Cell>{share.shareClass}</Table.Cell>
                                                                <Table.Cell textAlign='center'>{formatCurrency(Number(share.shareCount), true, 0)}</Table.Cell>
                                                                <Table.Cell textAlign='center'>{formatCurrency(Number(share.shareValue))}</Table.Cell>
                                                            </Table.Row>
                                                        }
                                                        else {
                                                            return <Table.Row key={i}>
                                                                <Table.Cell>
                                                                    <Grid>
                                                                        <Grid.Row>
                                                                            <Grid.Column width={3}>
                                                                                <label>Share:</label><br />
                                                                                <label>Share Count:</label><br />
                                                                                <label>Share Value:</label><br />
                                                                            </Grid.Column>
                                                                            <Grid.Column style={{ fontWeight: 'normal' }} >
                                                                                <div style={{ width: '45vw' }}>
                                                                                    {share.shareClass}<br />
                                                                                    {share.shareCount}<br />
                                                                                    {share.shareValue}<br />
                                                                                </div>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        }
                                                    })}
                                                    {!loading && shares.length === 0 &&
                                                        <EmptyGridMessage colSpan={showFullSize ? 3 : 1} message='No shares available' />
                                                    }
                                                </Table.Body>
                                            </Table>
                                        }
                                    </Grid.Column>
                                    <Grid.Column width={3} />
                                </GridRow>
                            </Grid>
                        }

                        <Divider horizontal section>
                            <Header as='h4'>
                                Loan Information
                            </Header>
                        </Divider>
                        <Form.Group widths='equal'>
                            <TextInput placeholder='Lender' name='lender' maxLength={255} readOnly={!editMode} showRequired={editMode} />
                            <TextInput placeholder='Term' name='term' maxLength={9} readOnly={!editMode} showRequired={editMode} />
                            <DateInput placeholder='Owned Since' name='ownedSince' mask={InputMasks.date} readOnly={!editMode} showRequired={editMode} />
                            <TextInput placeholder='Interest Rate' name='interestRate' maxLength={3} icon={<Icon name='percent' />} readOnly={!editMode} showRequired={editMode} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <SelectInput options={interestTypeOptions} placeholder='Fixed/Variable' name='interestType' disabled={!editMode} showRequired={editMode} />
                            <TextInput placeholder='Loan to Value' name='loanToValue' maxLength={6} icon={<Icon name='percent' />} readOnly={!editMode} showRequired={editMode} />
                            <SelectInput options={booleanOptions} placeholder='Interest Only' name='interestOnly' disabled={!editMode} showRequired={editMode} />
                            <TextInput placeholder='Debt Coverage Ratio' name='debtCoverage' maxLength={6} readOnly={!editMode} showRequired={editMode} />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            {!!values.interestOnly && <CurrencyInput placeholder='Years' name='interestYears' maxLength={4} readOnly={!editMode} showRequired={editMode} decimalScale={0} />}
                            <CurrencyInput placeholder='Annual Debt Service' name='annualDebtService' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={editMode ? '' : '$'} showRequired={editMode} />
                            <CurrencyInput placeholder='Total Loan Balance' name='totalLoan' maxLength={16} thousandSeparator=',' decimalScale={2} readOnly={!editMode} prefix={editMode ? '' : '$'} showRequired={editMode} onChange={(e) => runValuation(values.totalLoan, e)} />
                            <DateInput placeholder='Loan Start Date' name='loanStartDate' mask={InputMasks.date} readOnly={!editMode} showRequired={editMode} />

                        </Form.Group>

                        {editMode &&
                            <Segment clearing basic>
                                <Button primary floated='right' type='submit' disabled={isSubmitting} loading={isSubmitting}>Save</Button>
                                <Button onClick={() => reset(resetForm)} type="button" floated='right'>Cancel</Button>
                            </Segment>
                        }
                    </Form>
                )}
            </Formik>
        </Segment>
    )
}

export default FinancialsForm