import React, { useEffect, useState } from 'react'
import { Button, Grid, Header, HeaderContent, HeaderSubheader, Icon, Message, Segment, TabPane, Table, TableBody, TableCell, TableRow } from 'semantic-ui-react'
import SharesForm from './SharesForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ShareClassification } from '../../../../app/models/Investment/ShareClassification';
import { formatCurrency } from '../../../../shared/utils';
import { useStore } from '../../../../app/stores/store';
import { toast } from 'react-toastify';
import ConfirmButton from '../../../../shared/ConfirmButton';
import { Hurdle } from '../../../../app/models/Investment/Hurdle';
import ShareHurdleSummary from './ShareHurdleSummary/ShareHurdleSummary';

interface Props {
  investmentId: number;
}
function ShareManagement({ investmentId }: Props) {
  const { investmentStore } = useStore();
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);
  const [shares, setShares] = useState(new Array<ShareClassification>());
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [showDistributionSummary, setShowDistributionSummary] = useState(false);
  const [gpShare, setgpShare] = useState(new ShareClassification());

  useEffect(() => {
    investmentStore.getShareClassificationsByInvestment(investmentId)
      .then(r => {
        setgpShare(r.find(f => f.shareType === 2)!);
        setShares(r);
      });
  }, [investmentStore, investmentId])

  const handleClick = (index: number) => {
    setOpenIndexes((prevOpenIndexes) =>
      prevOpenIndexes.includes(index)
        ? prevOpenIndexes.filter((i) => i !== index)
        : [...prevOpenIndexes, index]
    );
  }

  const newHurdle = () => {
    return new Hurdle({
      id: 0,
      investmentShareClassificationId: 0,
      name: "",
      initialPercentage: 80,
      finalPercentage: 20,
      limitPercentage: 0,
      preferredReturnType: 0,
      catchUpOnPreferredReturns: null,
      honorOnlyCapitalEvents: null,
      dayCountConvention: 0,
      finalHurdle: 1,
      gpShareClassificationId: gpShare.id,
      hurdleType: 1
    });
  }

  const addNewClass = () => {
    const hurdle = newHurdle();
    const newItem = [...shares, {
      id: 0,
      shareClass: "",
      initialShareValue: 0,
      shareValue: "",
      currentShareValue: 0,
      shareCount: "",
      shareType: 0,
      shareHolderName: "",
      percentageOwnership: 0,
      costBasis: 0,
      totalValue: 0,
      investedAmount: 0,
      capitalRaised: "",

      typeOfEquity: "",
      minimumInvestmentAmount: "",
      maximumInvestmentAmount: "",
      ibutionSharePercentage: "",
      legalOwnershipPercentage: "",
      sortOrder: shares.length + 1,
      deleted: false,
      legalOwnershipPercentageError: false,
      investmentId: investmentId,
      isShareCountMatching: false,

      preferredReturnType: 0,
      returnPercentage: "",
      catchUpOnPreferredReturns: null,

      shareholders: [],
      hurdles: [hurdle],

      equityPercentage: 0,
    }];
    setShares(newItem);
    setOpenIndexes([...openIndexes, shares.length]);
    if (shares.length === 0) setDeleted(false);
  }

  const updateSharesWithErrors = (sharesInput: ShareClassification[]) => {
    const totalOwnership = sharesInput.reduce((total, share) => {
      return share.shareType !== 3 ? total + Number(share.legalOwnershipPercentage) : total;
    }, 0);

    const updatedShares = sharesInput.map(share => ({
      ...share,
      legalOwnershipPercentageError: totalOwnership !== 100,
      hurdles: share.shareType === 2 ? [] : share.hurdles,
    }));

    const hasErrors = updatedShares.some(share => share.legalOwnershipPercentageError);

    return { updatedShares, totalOwnership, hasErrors };
  };

  const deleteShare = (index: number) => {
    const updatedShares = shares.filter((_, i) => i !== index);

    const { updatedShares: sharesWithErrors, hasErrors } = updateSharesWithErrors(updatedShares);

    if (hasErrors) {
      setDeleted(true);
      setOpenIndexes(updatedShares.map((_, i) => i));
    }

    setShares(sharesWithErrors);
  };

  const updateName = (share: ShareClassification, index: number) => {
    const updatedItems = [...shares];
    updatedItems[index] = share;
    setShares(updatedItems);
  }

  const validateLPHurdles = (shareClassifications: ShareClassification[]) => {
    let errors: number[] = [];

    shareClassifications.forEach((share, shareIndex) => {
      if (share.shareType === 1) {
        if (share.hurdles && share.hurdles.length > 0) {
          share.hurdles.forEach((hurdle) => {
            if (!hurdle.name) {
              errors.push(shareIndex);
            }
            if (hurdle.initialPercentage === undefined || hurdle.initialPercentage === null) {
              errors.push(shareIndex);
            }
            if (hurdle.finalPercentage === undefined || hurdle.finalPercentage === null) {
              errors.push(shareIndex);
            }
            if (hurdle.finalHurdle === 0) {
              if (hurdle.limitPercentage === undefined || hurdle.limitPercentage === null) {
                errors.push(shareIndex);
              }
              if (hurdle.preferredReturnType === undefined || hurdle.preferredReturnType === null) {
                errors.push(shareIndex);
              }
              if (hurdle.catchUpOnPreferredReturns === undefined || hurdle.catchUpOnPreferredReturns === null) {
                errors.push(shareIndex);
              }
              if (hurdle.honorOnlyCapitalEvents === undefined || hurdle.honorOnlyCapitalEvents === null) {
                errors.push(shareIndex);
              }
              if (hurdle.dayCountConvention === undefined || hurdle.dayCountConvention === null) {
                errors.push(shareIndex);
              }
            }
          });
        }
      }
    });
    return errors;
  };

  const validateClasses = () => {
    const { updatedShares, totalOwnership, hasErrors } = updateSharesWithErrors(shares);

    if (hasErrors) {
      setOpenIndexes(shares.map((_, i) => i));
    }

    setShares(updatedShares);

    const shareAssignmentErrors: number[] = [];
    updatedShares.forEach((shareClassification, index) => {
      if (shareClassification.shareHolders && shareClassification.shareHolders.length > 0) {
        const totalShareHoldersShareCount = shareClassification.shareHolders.reduce((sum, holder) => sum + Number(holder.shareCount), 0);
        if (totalShareHoldersShareCount !== Number(shareClassification.shareCount)) {
          shareAssignmentErrors.push(index);
        }
      }
    });

    if (shareAssignmentErrors.length > 0) {
      setOpenIndexes(shareAssignmentErrors.map((i) => i));
    }

    const lpErrors = validateLPHurdles(updatedShares);
    if (lpErrors.length > 0) {
      setOpenIndexes(lpErrors.map((i) => i));
    }

    if (totalOwnership === 100 && shareAssignmentErrors.length === 0 && lpErrors.length === 0) {
      setLoading(true);
      investmentStore.saveShareClassification(investmentId, shares)
        .then((r) => {
          toast.success("Success!", { theme: "colored" });
          setShares(r.data);
          setDeleted(false);
        })
        .catch(() => {
          toast.error("There was an issue saving the share classification.", { theme: "colored" });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <TabPane>
      {showDistributionSummary ? (
        <>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column verticalAlign='middle' textAlign='left'>
                <Header as='h3'>
                  <HeaderContent>
                    Visualize Distributions
                    <HeaderSubheader>Review your waterfall structure</HeaderSubheader>
                  </HeaderContent>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign='right' verticalAlign='middle'>
                <Button onClick={() => setShowDistributionSummary(false)} floated='right' primary>Back</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ShareHurdleSummary shares={shares} />
        </>
      ) : (
        <>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column verticalAlign='middle' textAlign='left'>
                <Header as='h3'>
                  <HeaderContent>
                    Classes
                    <HeaderSubheader>Create classes and re-arrange by dragging the handle on the left.</HeaderSubheader>
                  </HeaderContent>
                </Header>
              </Grid.Column>
              <Grid.Column textAlign='right' verticalAlign='middle'>
                <Button onClick={addNewClass} floated='right' primary>New Class</Button>
                <Button onClick={() => setShowDistributionSummary(true)} floated='right' primary disabled={shares.filter(f => f.shareType !== 2).length === 0}>Visualize Distributions</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {
            shares.length > 0 &&
            <>
              {shares?.map((share, i) => (
                <Segment basic clearing key={i} className='classTable'>
                  <Table>
                    <TableBody>
                      <Table.Row>
                        <TableCell width={1}>
                          <span style={{ cursor: 'grab', padding: '10px' }}>
                            <FontAwesomeIcon icon={faBars} size='lg' />
                          </span>
                        </TableCell>
                        <TableCell width={9}>
                          <Icon link onClick={() => handleClick(i)}>
                            <FontAwesomeIcon icon={openIndexes.includes(i) ? faChevronRight : faChevronDown} size='lg' />
                          </Icon>
                          <span style={{ fontSize: '20px' }}>{share.shareClass}</span>
                        </TableCell>
                        <TableCell width={2}>
                          {share.shareType !== 2 &&
                            <>
                              Raise Amount <br /> {formatCurrency(Number(share.capitalRaised))}
                            </>
                          }
                        </TableCell>
                        <TableCell width={3}>
                          {share.shareType !== 3 &&
                            <>
                              Ownership of Entity<br /><label style={{ color: share.legalOwnershipPercentageError ? 'red' : '' }}>{share.legalOwnershipPercentage}%</label>
                            </>
                          }
                        </TableCell>
                        <TableCell width={1} textAlign='center'>
                          {share.shareType !== 2 &&
                            <ConfirmButton
                              id={i}
                              iconName='trash'
                              color='black'
                              value=''
                              confirmButtonText='Delete'
                              content='Delete Class?'
                              handleConfirm={deleteShare}
                            />
                          }
                        </TableCell>
                      </Table.Row>
                      {openIndexes.includes(i) && <TableRow id={i}>
                        <TableCell colSpan={6}>
                          <SharesForm share={share} updateShare={(share) => updateName(share, i)} gpShare={gpShare} />
                        </TableCell>
                      </TableRow>
                      }
                    </TableBody>
                  </Table>
                </Segment>
              ))}
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column verticalAlign='middle' textAlign='left' width={13}>
                    {deleted && <Message color='yellow' icon='warning sign' header='Review Allocations' content="You have deleted a share class. Please review the remaining class percentages to ensure they still sums up to 100%." />}
                  </Grid.Column>
                  <Grid.Column textAlign='right' verticalAlign='middle' width={3}>
                    <Button loading={loading} disabled={loading} primary type='submit' content='Validate and Save' onClick={validateClasses} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          }
        </>)}
    </TabPane >
  )
}

export default ShareManagement